import '@usb-shield/react-link/dist/library/styles/index.css'
import { USBColumn, USBGrid } from '@usb-shield/react-grid'
import {
  defaultColLayoutOptions,
  defaultGridOptions,
} from '@/utils/usb-grid.util'
import { BlockArrayWrapperData } from '@/components/BlockArrayWrapper/blockArrayWrapper.model'
import TextBlockWithImage from '@/components/BlockArrayWrapper/TextBlockWithImage/TextBlockWithImage'
import USBButton from '@usb-shield/react-button'
import USBLink from '@usb-shield/react-link'
import styles from '@/components/BlockArrayWrapper/blockArrayWrapper.module.scss'

// define default column width for full width use
let colWidth = {
  ...defaultColLayoutOptions,
  offset: {
    xlarge: 2,
    large: 0,
    medium: 0,
    small: 0,
  },
  spans: {
    xlarge: 12,
    large: 16,
    medium: 8,
    small: 4,
  },
}
// set default grid options for component containing grid
let containerGridOptions = defaultGridOptions

let containerGridStyles = styles.gridWrapper

const BlockArrayWrapper = ({
  title,
  shortDescription,
  textBlockWithImageParts,
  gridOption,
  levelDownHeads,
  ctaLink,
  ctaText,
  linkAsButton,
}: BlockArrayWrapperData) => {
  containerGridStyles = styles.gridWrapper
  const useTenColGridOption = gridOption == 'TenCol' ? true : false

  // configure grid and layout options for use in document sections component
  if ( useTenColGridOption){
    colWidth = {
      ...defaultColLayoutOptions,
      offset: {
        xlarge: 0,
        large: 0,
        medium: 0,
        small: 0,
      },
      spans: {
        xlarge: 10,
        large: 10,
        medium: 8,
        small: 4,
      },
    }
    containerGridOptions = {
      ...defaultGridOptions,
      columnCount: '10',
      padding: 'zero',
      type: 'vw'

    }
    containerGridStyles = styles.gridWrapperSections
    
  }

  const levelDownHeadsFlag = levelDownHeads || false

  // check for four-item array
  let fourUp = false
  if (textBlockWithImageParts.length % 4 === 0) {
    fourUp = true
  }

  // Analyze items array
  // if array contains objects with displayView set to OneColStacked, wrapping grid needs to be added
  const elements = textBlockWithImageParts
  let addWrappingGrid = true

  if (elements.filter((e) => e.displayView === 'OneColStacked').length == 0) {
    //array contains no OneColStacked views, so does not need a wrapping grid because grid is in the view;
    addWrappingGrid = false
  }

  const renderItems = textBlockWithImageParts.map(function (value: any, index: any) {
    let key = index
    const imagepath =
      value.image 
        ? process.env.CONTENT_DOMAIN + value.image
        : value.image

    return (
      <TextBlockWithImage
        key={key}
        title={value.title}
        shortDescription={value.shortDescription}
        ctaText={value.ctaText}
        ctaLink={value.ctaLink}
        image={imagepath}
        displayView={value.displayView}
        linkAsButton={value.linkAsButton}
        levelDownHeads={levelDownHeads}
        levelUpHeads={!title}
        fourUp={fourUp}
      />
    )
  })

  // Determine which grid to use based on gridOption value. Default desktop layout is 12-column.

  let gridSettings

  switch (gridOption) {
    // used for Section Wrapper
    case 'SixteenColNoPadding':
      gridSettings = {
        ...defaultGridOptions,
        padding: 'zero',
      }
      break
    // default grid for most cases
    case 'TwelveCol':
      gridSettings = {
        ...defaultGridOptions,
        gridGap: 'normal',
        columnCount: '12',
        padding: 'zero',
      }
      break

    // 10-col grid for placement in threeColSections displayView on documentation page
    case 'TenCol':
      gridSettings = {
        ...defaultGridOptions,
        gridGap: 'normal',
        columnCount: '10',
        padding: 'zero',
      }
      break
    case 'FourCol':
      gridSettings = {
        ...defaultGridOptions,
        gridGap: 'normal',
        type: 'sub',
        columnCount: '4',
        padding: 'zero',
      }
      break

    case 'ThreeCol':
      gridSettings = {
        ...defaultGridOptions,
        gridGap: 'normal',
        type: 'sub',
        columnCount: '3',
        padding: 'zero',
      }
      break

    default:
      gridSettings = {
        ...defaultGridOptions,
        columnCount: '12',
        padding: 'zero',
      }
  }

  let renderColumn = false

  let renderTextBlocks: any = []

  if (addWrappingGrid) {
    renderTextBlocks = (
      <USBGrid
        addClasses={styles.gridStyles}
        gridGap={gridSettings.gridGap}
        alignItems={gridSettings.alignItems}
        columnCount="12"
        justifyContent={gridSettings.justifyContent}
        display={gridSettings.display}
        padding={gridSettings.padding}
        type={gridSettings.type}
      >
        {renderItems}
      </USBGrid>
    )
  } else {
    renderTextBlocks = renderItems
  }

  renderColumn = !!title

  return (
    <USBGrid
      gridGap={containerGridOptions.gridGap}
      alignItems={containerGridOptions.alignItems}
      columnCount={containerGridOptions.columnCount}
      justifyContent={containerGridOptions.justifyContent}
      display={containerGridOptions.display}
      padding={containerGridOptions.padding}
      addClasses={containerGridStyles}
    >
      {renderColumn && (
        <USBColumn layoutOpts={colWidth} addClasses={styles.fullWidth}>
          {title?.trim() &&
            (levelDownHeadsFlag ? (
              <h3 className="headH3" data-testid="title">
                {title}
              </h3>
            ) : (
              <h2 className="headH2" data-testid="title">
                {title}
              </h2>
            ))}
          {shortDescription && (
            <div
              className="paragraph"
              data-testid="shortDescription"
              dangerouslySetInnerHTML={{ __html: shortDescription }}
            ></div>
          )}
        </USBColumn>
      )}
      <USBColumn layoutOpts={colWidth} addClasses={styles.fullWidth}>
        {renderTextBlocks}
      </USBColumn>
      {ctaLink && ctaLink !== '' && (
        <USBColumn
          layoutOpts={colWidth}
          addClasses={
            styles.fullWidth + ' ' + styles.blockArrayWrapperCtaSection
          }
        >
          {linkAsButton ? (
            <USBButton
              variant="primary"
              size="default"
              addClasses={styles.blockArrayWrapperCtaButton}
              handleClick={() => {
                window.open(ctaLink, '_self')
              }}
            >
              {' '}
              {ctaText}
            </USBButton>
          ) : (
            <USBLink
              linkType="arrow"
              href={ctaLink}
              addClasses={styles.blockArrayWrapperCtaLink}
            >
              {ctaText}
            </USBLink>
          )}
        </USBColumn>
      )}
    </USBGrid>
  )
}

export default BlockArrayWrapper
